@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.snapped{
  z-index: 60;
  background-color: white;
  width: 100%;
  left:0;

  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}
.link-unstyled {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the default text color */
}
.ace_layer{
  z-index: -10 !important;
}
.link-unstyled:hover {
  /* color: inherit; */
  color:black !important;
  text-decoration: none !important;
   /* Use the default text color on hover */

}
.snapped .logo{
display: block !important;
}
.internetOptions{
  display: flex !important;
  flex-direction: row !important;
}
.comp{
  text-align: center;
  /* justify-content: center; */
  /* align-items: center;
  display: flex !important;
  flex-direction: row; */

  /* min-width: 150px */
}
.row_class{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.card_item{
  width: 32%;
  /* margin-top:3px; */
margin:5px;
  /* margin:5px; */
  /* flex:1; */
  /* max-width: 0px; */
  min-width: 250px;
}
.com_btn:hover{
  background-color: orange  !important;
  color: white !important;
  border-color: orange;
}
.com_title:hover{
  color: orange !important;
}
@media screen and (max-width: 600px) {
  .card_item {
   flex:1 1
  }
}

.card_title{
  font-size: 14px;
  color:orange
}
.truncate-text{
  height: 75px;
  line-height:15px;
  font-size: 12px;
  /* white-space: nowrap; */
  overflow: hidden; 
  /* text-overflow: ellipsis; */
}
.modal_detail{
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  width: 100vw;
  height:100vh;
  left:0;top:0;
  z-index: 100;
  display: flex;;flex-direction: row;align-items: center;justify-content: center;
}.image__container {

  transition: transform 0.3s ease;
}


.zoom:hover {
  transform: scale(1.1);
}

.navbar{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  padding-left:0px !important;
  padding-top: 0px;
  padding-right:0px !important;
}
.navoption2{
min-width: 100px;
text-align: center;
padding-top:10px;
overflow: hidden;
position: relative;
cursor:pointer
}

/* Styling for the child div */
.child {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 5px;
  background-color: orange;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

/* Styling for hover effect */
.navoption2:hover .child {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.titlehover{cursor: pointer;
  color:black
}
.titlehover:hover{
  
  color:orange !important;
}
.legend {
  display: none;
}
.load-wraper{
  /* position: relative; */
  /* height: 100%;
  width: 100%; */
  background-color: rgb(211,211,211);
  /* z-index: 44; */
  overflow: hidden;
  /* border-radius: 5px; */
}
.activity{
  z-index: -1;
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
  background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
  animation: loading 1s infinite;
  z-index: 45;
}

@keyframes loading {
  0%{
    left: -45%;
  }
  100%{
    left: 100%;
  }
}
@media (max-width: 800px) {
  .my-div {
    display: none !important;
  }
  
}
.divider{
  display: block;
}
@media (max-width: 720px) {
  .divider{
    display: none !important;
  }
  
}
.divider2{
  display: none ;
}
@media (max-width: 720px) {
  .divider2{
    display: block ;
  }
  
}
.sidenav {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: white;
  transition: left 0.3s ease-in-out;
  z-index: 70;
}

.sidenav-open {
  left: 0;
}

.sidenav-closed {
  left: -300px;
}
.navbar-toggler-icon {
  background-color: black;
}
.blink {
  /* animation: blinker 1s ease-in-out infinite; */
  transform-origin: center;
}

@keyframes blinker {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
.faq_title:hover{
color: orange !important;
}
.btn-com:hover{
  /* background-color: orange; */
  color:white !important;
  /* border-color:orange; */

}

.surveycard{
  margin:10px;
  border-radius: 5px;
  /* width:100%; */

  min-width:250px;
  max-width: 600px;
  /* height:100px; */
  /* font-size: 20px;
  font-weight:bold ; */
  display: flex;
  flex-direction: row;
 justify-content: flex-start;
   /* align-items: center; */
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23);
}
.surveycard:hover{
  background-color: #f8f8f8 !important
}


button.back-to-top{
  margin: 0 !important;
  padding: 0 !important;
  background: #fff;
	height: 0px;
  width: 0px;
  overflow: hidden;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
  color: transparent;
	clear: both;
  visibility: hidden;
  position: fixed;
  cursor: pointer;
  display: block;
  border: none;
  right: 50px;
	bottom: 75px;
  font-size: 0px;
  outline: 0 !important;
  z-index: 99;
  transition: all .3s ease-in-out;
}
button.back-to-top:hover,button.back-to-top:active,button.back-to-top:focus{  outline: 0 !important;}
button.back-to-top::before,
button.back-to-top::after {
  content: "";
    display: block;
    vertical-align: middle;
    border-bottom: solid 10px #EA5D5F;
    border-left: solid 10px transparent;
    line-height: 0;
    border-right: solid 10px transparent;
    height: 0;
    margin: 18px auto 0;
    width: 0;
  border-radius:20px;
  visibility: hidden;
}
button.back-to-top.show::after,
button.back-to-top.show::before{
  visibility: visible;
}
button.back-to-top::after {
  border-bottom-color:#fff;
      position: relative;
  top:-24px;
}
button.back-to-top.show {
  display: block;
  background: #fff;
  color: #00ab6c;
  font-size: 25px;
  right: 25px;
	bottom: 50px;
  height: 50px;
  width: 50px;
  visibility: visible;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
}
button.back-to-top.show:active {
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
}

.sa-visualizer__toolbar{
  display: none !important;
}
.sa-panel__header{
  display: none !important;
}


.scrollable-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  overflow-x: auto;
  align-items: center;
  -ms-overflow-style: none; /* Hide scrollbar on Internet Explorer 10+ */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
}

.scrollable-div::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome, Safari, and Opera */
}
.required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}
.slide-in {
  position: relative;
  /* width: 200px;
  height: 200px; */
  /* background-color: #f2f2f2; */
  transition: left 0.5s ease;
  left: -200px;
}

.slide-in.show {
  left: 0;
}

/* .scrollable-container {

  overflow: hidden;
} */

.scrollable-container::-webkit-scrollbar {
  /* Hide the scrollbar in Webkit browsers */
  display: none;
}

.scrollable-container {
  /* Hide the scrollbar in Firefox */
  scrollbar-width: none;
}
.carousel {
  overflow-y: hidden;
  height: 100%; /* Optional: Adjust the height as needed */
}
/* .navoption{
  background-color: #F4B221;
  color: white;
min-width:80px;
cursor: pointer;
padding:10px;
text-align: center;
}
body {
  font-family: 'Roboto', sans-serif;
}

.menu{  margin-left: 40px;}
.navoption:hover{
  background-color: #E2A623;
}
.nav{
  padding:0 !important;
  align-items: start !important;
}

.glow {
  margin-top:35px;
  font-size: 55px !important;
  color: #fff;
  text-align: left;
  animation: glow 1s ease-in-out infinite alternate;
  font-family: Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", "sans serif";
}

@-webkit-keyframes glow {
  from {
text-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 2px lightblue, 0 0 2px blue, 0 0 4px blue, 0 0 4px blue, 0 0 4px blue;
  }
  
  to {
    text-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 2px lightblue, 0 0 2px blue, 0 0 4px blue, 0 0 4px blue, 0 0 4px blue;
  
  }
}

.content{
  margin:10px;
}
.card2{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23);
}
.bounce{
  position: relative;
margin-left:12px;
  animation: bounce 1s infinite;
}
.comp{
  text-align: center;
}
.comp:hover{
  background-color: rgba(255,255,255,.2);
}
@keyframes bounce {
  0% {
    right: 0;
    animation-timing-function: ease-out;
  }
  50% {
    right: 20px;
    animation-timing-function: ease-in;
  }
  100% {
    right: 0;
    animation-timing-function: ease-out;
  }
}
.legend {
  display: none;
}
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
} */


a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; 
color: orange !important;
}


a:active { text-decoration: none; }
.btnlink:hover{
  color: orange !important;
}
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Set horizontal scroll */
.example {
  overflow-x: scroll;
}
/* Add horizontal scroll */
.my-element {
  overflow-x: scroll;
}

/* Hide scrollbars */
.my-element::-webkit-scrollbar {
  display: none;
}

.my-element {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.swiper {
  width: 100%;
   height: 100% !important;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  /* display:flex; */
  /* padding-top: 50px;
  padding-bottom: 50px; */
}
.swiper-wrapper {
 
  height: auto !important;}
.swiper-slide {
  background-position: center;
  background-size: cover;
  /* width: 300px;
  height: 300px;  */
   display: flex !important;
  justify-content: center;
  flex-direction: column;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.swiper-slide{

}
.applybtn{
  background-color:orange !important;
  transition: all .2s ease-in-out;

  }
.applybtn:hover{
background-color: #FFA000 !important;
transform: scale(1.1);
transition: all .2s ease-in-out;

}
/* .expand2::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
} */
.expand {
  overflow: hidden;
  height: 0;
  width:'100%';
  background: #B3E5FC;
  /* padding:5px; */

  opacity:1;
  transition: height 0.5s ease-in-out,opacity 0.3s ease;
}
.expand2 {
  height:250px;  
    /* padding:5px; */
  transition: height 0.5s ease-in-out,opacity 0.3s ease;

  background: #B3E5FC;

  opacity:1;
  overflow-y: auto;
}
.expand2::-webkit-scrollbar-track {
  background-color:#4FC3F7; /* Set your desired background color */
}

.expand2::-webkit-scrollbar-thumb {
  background-color: #0288D1;
 
  border-radius: 8px;
}

.expand2::-webkit-scrollbar {
  width: 8px; 
  background-color: #f1f1f1; 
}
.frost5{

  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23);
  background-color: rgba(5,78, 255, 0.8);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.frost6{

  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23);
  background-color: rgba(0,0,0,0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.scrollable-container {
  overflow: auto; /* Enable scroll behavior */
  scrollbar-width: thin; /* Width of the scroll bar */
  scrollbar-color: #888 #f1f1f1; /* Color of the scroll handle and track */
}

.scrollable-container::-webkit-scrollbar {
  width: 10px; /* Width of the scroll bar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scroll track */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll handle */
  border-radius: 5px; /* Border radius of the scroll handle */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scroll handle on hover */
}
.galleryCard{
  transform: scale(1.0);
  transition: transform 0.3s ease-in-out;
}
.galleryCard:hover{
  transition: transform 0.3s ease-in-out;
  transform: scale(1.02);
  z-index: 18;
}
.zoom-center-container {
  position: fixed;
  /* transition: width 0.5s ease-in-out, height 0.3s ease-in-out; */
  top: 20%;
  left: 50%;
  transform: translate(+200%, -20%);
  width: 95%;
  max-width: 1366px;
  /* max-height: 400px; */
  /* display:block; */
 height:100vh;
  z-index: 20;
  text-align: center;
  transition: transform 0.5s ease-in-out;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 100px;
  /* background-color: white !important; */
}

.zoom-center-container.zoomed {
  position: fixed;
  transition: transform 0.5s ease-in-out;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 95%;
  max-width: 1366px;
  /* max-height: 400px; */
  /* display:block; */
 height:100vh;
 overflow: scroll;
 /* min-height:300px; */
 text-align: center;
 display:flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: space-around;
  /* height: 300px/; */
  padding-top: 100px;
  /* background-color: white !important; */
    /* animation: bounce 0.6s; */
}


.zoom-center-container {
  overflow: auto; /* Enable scroll behavior */
  scrollbar-width: 0px; /* Width of the scroll bar */
  scrollbar-color:transparent; /* Color of the scroll handle and track */
}

.zoom-center-container::-webkit-scrollbar {
  width: 0px; /* Width of the scroll bar */
  height:0px
}

.input_test{
  margin-right: 5px;
  /* margin-top: 5px; */
  width:20px;
  height: 15px;

}
.zoom-center-container {
/* width: 100% !important;
max-width: 100vw !important; */
overflow: hidden;
}

